import React from 'react';
import './css/index.scss';
import { ToastContainer } from 'react-toastify';
import NavBar from '../../components/NavBar';

function Wrapper({ children }) {
  return (
    <div className="wrapper">
      <NavBar />
      <div className="container">
        {children}
      </div>

      <ToastContainer />
    </div>
  );
}

export default Wrapper;
