import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bounce, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import sendRequest from '../sendRequest';
import Api from '../../Api';
import LocalStorageServices from '../LocalStorageServices';
import { setCreatedDrawings, setShouldCheckDrawingTasks } from '../../store/actions/drawings';

const useCheckDrawingTasks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, isAdmin } = useSelector((store) => store.account);
  const { shouldCheckDrawingTasks } = useSelector((store) => store.drawings);

  const checkDrawingTask = async (id) => {
    const data = await sendRequest({
      request: Api.checkDrawingTask,
      payload: id,
      warnErrorText: 'while checking drawing task',
    });

    if (data?.status === 'done' && data.draws?.length) {
      toast.success(`Ваши чертежи (${data.draws.length}) успешно были созданы. Нажмите сюда, чтоб перейти к их редактированию`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        draggable: false,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
        onClick: () => {
          dispatch(setCreatedDrawings(data.draws));
          navigate('/drawings/edit');
        },
        style: { cursor: 'pointer' },
      });

      LocalStorageServices.removeDrawingTaskId(id);
    }
  };

  useEffect(() => {
    let interval;
    if (token && isAdmin && shouldCheckDrawingTasks) {
      interval = setInterval(() => {
        const drawingTasks = LocalStorageServices.getDrawingTasks();
        if (drawingTasks?.length) {
          // eslint-disable-next-line no-restricted-syntax
          for (const taskId of drawingTasks) {
            checkDrawingTask(taskId).then();
          }
        }
      }, 3000);
    }

    return () => (interval ? clearInterval(interval) : null);
  }, [token, isAdmin, shouldCheckDrawingTasks]);

  useEffect(() => {
    const drawingTasks = LocalStorageServices.getDrawingTasks();
    if (drawingTasks?.length) {
      dispatch(setShouldCheckDrawingTasks(true));
    }
  }, []);
};

export default useCheckDrawingTasks;
