import {
  CREATE_DRAWINGS_FAIL,
  CREATE_DRAWINGS_REQUEST,
  CREATE_DRAWINGS_SUCCESS, DELETE_DRAWING_FAIL, DELETE_DRAWING_REQUEST, DELETE_DRAWING_SUCCESS,
  EDIT_DRAWING_FAIL,
  EDIT_DRAWING_REQUEST,
  EDIT_DRAWING_SUCCESS,
  GET_DRAWINGS_FAIL,
  GET_DRAWINGS_REQUEST,
  GET_DRAWINGS_SUCCESS, SET_CREATED_DRAWINGS,
  SET_DRAWINGS, SET_SHOULD_CHECK_DRAWING_TASKS,
} from '../actions/drawings';
import { LOG_OUT } from '../actions/account';

const initialState = {
  drawings: {},
  drawingsLoading: false,
  drawingsError: '',

  singleDrawing: {},

  savingDrawingLoading: false,
  savingDrawingError: '',

  gettingSingleDrawingLoading: false,
  gettingSingleDrawingError: '',

  createdDrawings: [],
  creatingDrawingsLoading: false,
  creatingDrawingsError: '',

  editingDrawingLoading: false,
  editingDrawingError: '',

  deletingDrawingLoading: false,
  deletingDrawingError: '',

  shouldCheckDrawingTasks: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_DRAWINGS_REQUEST: {
      return {
        ...state,
        drawingsLoading: true,
        drawingsError: '',
      };
    }
    case GET_DRAWINGS_SUCCESS: {
      return {
        ...state,
        drawings: action.payload?.draws,
        drawingsLoading: false,
        drawingsError: '',
      };
    }
    case SET_DRAWINGS: {
      return {
        ...state,
        drawings: action.payload,
        drawingsLoading: true,
        drawingsError: '',
      };
    }
    case GET_DRAWINGS_FAIL: {
      return {
        ...state,
        drawingsLoading: false,
        drawingsError: 'error',
      };
    }

    case CREATE_DRAWINGS_REQUEST: {
      return {
        ...state,
        createdDrawings: [],
        creatingDrawingsLoading: true,
        creatingDrawingsError: '',
      };
    }
    case CREATE_DRAWINGS_SUCCESS: {
      return {
        ...state,
        createdDrawings: [],
        creatingDrawingsLoading: false,
        creatingDrawingsError: '',
        shouldCheckDrawingTasks: true,
      };
    }
    case SET_SHOULD_CHECK_DRAWING_TASKS: {
      return {
        ...state,
        shouldCheckDrawingTasks: action.payload,
      };
    }
    case SET_CREATED_DRAWINGS: {
      return {
        ...state,
        createdDrawings: action.payload,
      };
    }
    case CREATE_DRAWINGS_FAIL: {
      return {
        ...state,
        createdDrawings: [],
        creatingDrawingsLoading: false,
        creatingDrawingsError: action.payload,
      };
    }

    case EDIT_DRAWING_REQUEST: {
      return {
        ...state,
        editingDrawingLoading: true,
        editingDrawingError: '',
      };
    }
    case EDIT_DRAWING_SUCCESS: {
      const editedId = action.payload;
      return {
        ...state,
        createdDrawings: state.createdDrawings.map((item) => (item.id !== editedId ? item : { ...item, isDisabled: true })),
        editingDrawingLoading: false,
        editingDrawingError: '',
      };
    }
    case EDIT_DRAWING_FAIL: {
      return {
        ...state,
        editingDrawingLoading: false,
        editingDrawingError: action.payload,
      };
    }

    case DELETE_DRAWING_REQUEST: {
      return {
        ...state,
        deletingDrawingLoading: true,
        deletingDrawingError: '',
      };
    }
    case DELETE_DRAWING_SUCCESS: {
      const deletedId = action.payload;
      return {
        ...state,
        createdDrawings: state.createdDrawings.map((item) => (item.id !== deletedId ? item : { ...item, isDisabled: true })),
        deletingDrawingLoading: false,
        deletingDrawingError: '',
      };
    }
    case DELETE_DRAWING_FAIL: {
      return {
        ...state,
        deletingDrawingLoading: false,
        deletingDrawingError: action.payload,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
