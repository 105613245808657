export default class {
  static getToken = () => {
    const token = localStorage.getItem('token');
    return token || '';
  };

  static setToken = (token) => {
    localStorage.setItem('token', token);
  };

  static removeToken = () => {
    localStorage.removeItem('token');
  };

  static getLang = () => {
    const lang = localStorage.getItem('lang');
    return lang || 'ru';
  };

  static setLang = (lang) => {
    localStorage.setItem('lang', lang);
  };

  static getAppointmentsList = (objectId) => {
    let list = localStorage.getItem('appointments_list');
    if (typeof list !== 'string') return [];

    list = JSON.parse(list);

    if (objectId) {
      list = list.filter((item) => item.info.object_id === objectId);
    }

    return list;
  };

  static getAppointment = (id) => {
    const list = this.getAppointmentsList();
    return list.find((item) => item.id === id) || null;
  };

  static appendAppointment = (data) => {
    const list = this.getAppointmentsList();
    list.push(data);
    localStorage.setItem('appointments_list', JSON.stringify(list));
  };

  static updateAppointment = (id, data) => {
    const list = this.getAppointmentsList();
    const newList = list.map((item) => (item.id === id ? data : item));
    localStorage.setItem('appointments_list', JSON.stringify(newList));
  };

  static deleteAppointment = (id) => {
    const list = this.getAppointmentsList();
    const newList = list.filter((item) => item.id !== id);
    localStorage.setItem('appointments_list', JSON.stringify(newList));
  };

  static getDrawingTasks = () => {
    const list = localStorage.getItem('drawing_tasks');
    return list ? JSON.parse(list) : [];
  };

  static addDrawingTaskId = (id) => {
    const list = this.getDrawingTasks() || [];
    list.push(id);
    localStorage.setItem('drawing_tasks', JSON.stringify(list));
  };

  static removeDrawingTaskId = (id) => {
    if (id === 'all') return localStorage.setItem('drawing_tasks', JSON.stringify([]));

    let list = this.getDrawingTasks() || [];
    list = list.filter((i) => i !== id);
    localStorage.setItem('drawing_tasks', JSON.stringify(list));
  };
}
