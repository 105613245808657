import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  CREATE_DRAWINGS_FAIL,
  CREATE_DRAWINGS_REQUEST,
  CREATE_DRAWINGS_SUCCESS, DELETE_DRAWING_FAIL, DELETE_DRAWING_REQUEST, DELETE_DRAWING_SUCCESS,
  EDIT_DRAWING_FAIL,
  EDIT_DRAWING_REQUEST,
  EDIT_DRAWING_SUCCESS,
  GET_DRAWINGS_FAIL,
  GET_DRAWINGS_REQUEST,
  GET_DRAWINGS_SUCCESS,
} from '../actions/drawings';
import LocalStorageServices from '../../helpers/LocalStorageServices';

export default function* watcher() {
  yield takeLatest(GET_DRAWINGS_REQUEST, getDrawings);
  yield takeLatest(CREATE_DRAWINGS_REQUEST, createDrawings);
  yield takeLatest(EDIT_DRAWING_REQUEST, editDrawing);
  yield takeLatest(DELETE_DRAWING_REQUEST, deleteDrawing);
}

function* getDrawings(action) {
  try {
    const { data } = yield call(Api.getAllDrawings, action.payload);

    yield put({
      type: GET_DRAWINGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting drawings');
    console.log(e?.response?.data);
    yield put({
      type: GET_DRAWINGS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* createDrawings(action) {
  try {
    const { data } = yield call(Api.createDrawings, action.payload);

    LocalStorageServices.addDrawingTaskId(data.task_id);

    yield put({
      type: CREATE_DRAWINGS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while creating drawings');
    console.log(e?.response?.data);
    yield put({
      type: CREATE_DRAWINGS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* editDrawing(action) {
  try {
    yield call(Api.editDrawing, action.payload);

    yield put({
      type: EDIT_DRAWING_SUCCESS,
      payload: action.payload.id,
    });
  } catch (e) {
    console.log(e, 'while editing drawing');
    console.log(e?.response?.data);
    yield put({
      type: EDIT_DRAWING_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteDrawing(action) {
  try {
    yield call(Api.deleteDrawing, action.payload);

    yield put({
      type: DELETE_DRAWING_SUCCESS,
      payload: action.payload,
    });
  } catch (e) {
    console.log(e, 'while deleting drawing');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_DRAWING_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
