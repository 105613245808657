import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useJwt } from 'react-jwt';
import Login from './pages/LoginPages/Login';
import ProtectedRoutes from './navigation/ProtectedRoutes';
import UnprotectedRoutes from './navigation/UnprotectedRoutes';
import LocalStorageServices from './helpers/LocalStorageServices';
import {
  getProfileRequest, setIsAdmin, setIsRoot, setLanguage, setToken,
} from './store/actions/account';
import Events from './pages/DocumentsPages/Events';
import Prescriptions from './pages/DocumentsPages/Prescriptions';
import Companies from './pages/ConstructionPages/Companies';
import SingleCompany from './pages/ConstructionPages/SingleCompany';
import Letterheads from './pages/AdministrationPages/Letterheads';
import SingleLetterhead from './pages/AdministrationPages/SingleLetterhead';
import Users from './pages/AdministrationPages/Users';
import SingleUser from './pages/AdministrationPages/SingleUser';
import SingleProject from './pages/ConstructionPages/SingleProject';
import Projects from './pages/ConstructionPages/Projects';
import SingleObject from './pages/ConstructionPages/SingleObject';
import Objects from './pages/ConstructionPages/Objects';
import Instruction from './pages/AdditionalPages/Instruction';
import SingleAlloc from './pages/ConstructionPages/SingleAlloc';
import Allocs from './pages/ConstructionPages/Allocs';
import AdminRoutes from './navigation/AdminRoutes';
import SinglePrescriptionEdit from './pages/DocumentsPages/SinglePrescription/Edit';
import SinglePrescriptionView from './pages/DocumentsPages/SinglePrescription/View';
import SingleActEdit from './pages/DocumentsPages/SingleAct/Edit';
import SingleActView from './pages/DocumentsPages/SingleAct/View';
import Configurations from './pages/RootPages/Configurations';
import SingleConfiguration from './pages/RootPages/SingleConfiguration';
import SingleClient from './pages/RootPages/SingleClient';
import Clients from './pages/RootPages/Clients';
import Acts from './pages/DocumentsPages/Acts';
import Profile from './pages/ProfilePages/Profile';
import Checklists from './pages/AdditionalPages/Checklists';
import SingleChecklistCategory from './pages/AdditionalPages/SingleChecklistCategory';
import SingleChecklistTemplate from './pages/AdditionalPages/SingleChecklistTemplate';
import SingleCheckup from './pages/DocumentsPages/SingleCheckup';
import Checkups from './pages/DocumentsPages/Checkups';
import Drawings from './pages/ConstructionPages/Drawings';
import AddDrawings from './pages/ConstructionPages/SingleDrawingPages/AddDrawings';
import EditDrawings from './pages/ConstructionPages/SingleDrawingPages/EditDrawings';
import SingleDrawingDetails
  from './pages/ConstructionPages/SingleDrawingPages/SingleDrawingDetails';
import AppointmentCalendar from './pages/LoginPages/AppointmentPart/Calendar';
import AddAppointment from './pages/LoginPages/AppointmentPart/AddAppointment';
import ViewAppointment from './pages/LoginPages/AppointmentPart/ViewAppointment';
import SingleCheckupRequest from './pages/DocumentsPages/SingleCheckupRequest';
import useCheckDrawingTasks from './helpers/hooks/useCheckDrawingTasks';

function App() {
  useCheckDrawingTasks();

  const dispatch = useDispatch();
  const token = useSelector((store) => store.account.token);

  const { decodedToken, isExpired } = useJwt(token);

  const { 'is-admin': isAdmin, id: userId } = decodedToken || {};

  const isRoot = userId === 0;
  const authorizedFirstScreen = isRoot ? '/users' : '/events';

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const tokenFromStorage = LocalStorageServices.getToken();
    const langFromStorage = LocalStorageServices.getLang();
    dispatch(setLanguage(langFromStorage));

    if (tokenFromStorage) {
      dispatch(setToken(tokenFromStorage));
    }
    setReady(true);
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(getProfileRequest());
    }
  }, [token]);

  useEffect(() => {
    if (isRoot) {
      dispatch(setIsRoot(isRoot));
    }
    if (typeof isAdmin === 'boolean') {
      dispatch(setIsAdmin(isAdmin));
    }
  }, [isAdmin]);

  if (!isReady) {
    return null;
  }

  return (
    <Routes>
      <Route element={<UnprotectedRoutes authorizedFirstScreen={authorizedFirstScreen} token={token && !isExpired && Number.isFinite(userId)} />}>
        <Route path="/login" element={<Login />} />
      </Route>
      {isRoot ? (
        <Route element={<ProtectedRoutes token={token && !isExpired && Number.isFinite(userId)} />}>

          <Route path="/users">
            <Route path="add" element={<SingleUser />} />
            <Route path="edit" element={<SingleUser />} />
            <Route path="" element={<Users />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/configuration">
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/configuration" />}>
              <Route path="add" element={<SingleConfiguration />} />
              <Route path="edit" element={<SingleConfiguration />} />
            </Route>
            <Route path="" element={<Configurations />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/clients">
            <Route path="add" element={<SingleClient />} />
            <Route path="edit" element={<SingleClient />} />
            <Route path="" element={<Clients />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/profile" element={<Profile />} />

        </Route>
      ) : (
        <Route element={<ProtectedRoutes token={token && !isExpired && Number.isFinite(userId)} />}>

          <Route path="/events" element={<Events />} />

          <Route path="/prescriptions">
            <Route path="edit" element={<SinglePrescriptionEdit />} />
            <Route path="view" element={<SinglePrescriptionView />} />
            <Route path="" element={<Prescriptions />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/acts">
            <Route path="edit" element={<SingleActEdit />} />
            <Route path="view" element={<SingleActView />} />
            <Route path="" element={<Acts />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/checkups">
            <Route path="edit" element={<SingleCheckup />} />
            <Route path="request" element={<SingleCheckupRequest />} />
            <Route path="" element={<Checkups />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/partners">
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/partners" />}>
              <Route path="add" element={<SingleCompany />} />
              <Route path="edit" element={<SingleCompany />} />
            </Route>
            <Route path="" element={<Companies />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/projects">
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/projects" />}>
              <Route path="add" element={<SingleProject />} />
              <Route path="edit" element={<SingleProject />} />
            </Route>
            <Route path="" element={<Projects />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/objects">
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/objects" />}>
              <Route path="add" element={<SingleObject />} />
              <Route path="edit" element={<SingleObject />} />
            </Route>
            <Route path="" element={<Objects />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/allocated_users">
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/allocated_users" />}>
              <Route path="add" element={<SingleAlloc />} />
              <Route path="edit" element={<SingleAlloc />} />
            </Route>
            <Route path="" element={<Allocs />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/drawings">
            <Route path="details" element={<SingleDrawingDetails />} />
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/drawings" />}>
              <Route path="edit" element={<EditDrawings />} />
              <Route path="add" element={<AddDrawings />} />
            </Route>
            <Route path="" element={<Drawings />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route element={<AdminRoutes isAdmin={isAdmin} />}>
            <Route path="/company_letterheads">
              <Route path="add" element={<SingleLetterhead />} />
              <Route path="edit" element={<SingleLetterhead />} />
              <Route path="" element={<Letterheads />} />
              <Route path="*" element={<Navigate to="" replace />} />
            </Route>
            <Route path="/users">
              <Route path="add" element={<SingleUser />} />
              <Route path="edit" element={<SingleUser />} />
              <Route path="" element={<Users />} />
              <Route path="*" element={<Navigate to="" replace />} />
            </Route>
          </Route>

          <Route path="/instructions" element={<Instruction />} />

          <Route path="/checklists">
            <Route element={<AdminRoutes isAdmin={isAdmin} to="/checklists" />}>
              <Route path="category/add" element={<SingleChecklistCategory />} />
              <Route path="category/edit" element={<SingleChecklistCategory />} />
              <Route path="template/add" element={<SingleChecklistTemplate />} />
              <Route path="template/edit" element={<SingleChecklistTemplate />} />
            </Route>
            <Route path="" element={<Checklists />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Route>

          <Route path="/profile" element={<Profile />} />

        </Route>
      )}

      <Route path="/z/:client_id/:object_id/:lang">
        <Route path="edit" element={<AddAppointment />} />
        <Route path="view" element={<ViewAppointment />} />
        <Route path="" element={<AppointmentCalendar />} />
        <Route path="*" element={<Navigate to="" replace />} />
      </Route>

      <Route path="*" element={<Navigate to={token && !isExpired && Number.isFinite(userId) ? authorizedFirstScreen : '/login'} replace />} />
    </Routes>
  );
}

export default App;
