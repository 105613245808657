export const GET_DRAWINGS_REQUEST = 'GET_DRAWINGS_REQUEST';
export const GET_DRAWINGS_SUCCESS = 'GET_DRAWINGS_SUCCESS';
export const GET_DRAWINGS_FAIL = 'GET_DRAWINGS_FAIL';
export function getDrawingsRequest(data) {
  return {
    type: GET_DRAWINGS_REQUEST,
    payload: data,
  };
}

export const SET_DRAWINGS = 'SET_DRAWINGS';
export function setDrawings(data) {
  return {
    type: SET_DRAWINGS,
    payload: data,
  };
}

export const CREATE_DRAWINGS_REQUEST = 'CREATE_DRAWINGS_REQUEST';
export const CREATE_DRAWINGS_SUCCESS = 'CREATE_DRAWINGS_SUCCESS';
export const CREATE_DRAWINGS_FAIL = 'CREATE_DRAWINGS_FAIL';
export function createDrawingsRequest(data) {
  return {
    type: CREATE_DRAWINGS_REQUEST,
    payload: data,
  };
}

export const EDIT_DRAWING_REQUEST = 'EDIT_DRAWING_REQUEST';
export const EDIT_DRAWING_SUCCESS = 'EDIT_DRAWING_SUCCESS';
export const EDIT_DRAWING_FAIL = 'EDIT_DRAWING_FAIL';
export function editDrawingRequest(data) {
  return {
    type: EDIT_DRAWING_REQUEST,
    payload: data,
  };
}

export const DELETE_DRAWING_REQUEST = 'DELETE_DRAWING_REQUEST';
export const DELETE_DRAWING_SUCCESS = 'DELETE_DRAWING_SUCCESS';
export const DELETE_DRAWING_FAIL = 'DELETE_DRAWING_FAIL';
export function deleteDrawingRequest(data) {
  return {
    type: DELETE_DRAWING_REQUEST,
    payload: data,
  };
}

export const SET_SHOULD_CHECK_DRAWING_TASKS = 'SET_SHOULD_CHECK_DRAWING_TASKS';
export function setShouldCheckDrawingTasks(data) {
  return {
    type: SET_SHOULD_CHECK_DRAWING_TASKS,
    payload: data,
  };
}

export const SET_CREATED_DRAWINGS = 'SET_CREATED_DRAWINGS';
export function setCreatedDrawings(data) {
  return {
    type: SET_CREATED_DRAWINGS,
    payload: data,
  };
}
